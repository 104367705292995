<template>
    <div class="forget">
        <div class="loginTop" v-if="mobileFlag">
                <img class="title_logo" src="../../assets//images/CwLogo.svg" alt="">
           
            <div class="title_icons">
                <div class="menu_icon" @click="changeLanguage(languageTxt)">
                    <img src="../../assets/images/LanguageIcon.svg" alt="">
                    {{ languageTxt == 'zh' ?'CN':'EN'  }}
                    
                </div>
            </div>
        </div>
        <div class="forgetDiv">
            <div class="forgetLeft">
                <div class="forgetTitle">
                    <span class="forgetTxt">
                        {{ $t('forget_title') }}
                    </span>
                   
                </div>
                <div v-show="step == 1">
                    <el-form   class="forgetForm" ref="forgetForm" label-position="top" label-width="80px" :model="forgetForm">
                        <el-form-item :label="$t('forget_email')" prop="email" :rules="[
                            { required: true, message: $t('login_emailNotNull'), trigger: 'blur' },
                            { type: 'email', message: $t('login_emailError'), trigger: ['blur', 'change'] }
                            ]">
                            <el-input v-model="forgetForm.email"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('forget_code')" prop="code"  :rules="[
                            { required: true, message: $t('forget_codeNotNull'), trigger: 'blur' }, 
                            ]">
                            <el-input v-model="forgetForm.code" autocomplete="off"   ></el-input>
                            <el-button class="sendCodeBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="sendCode" :disabled="dis">
                                
                                <span v-if="show">{{ $t('forget_sendcode') }}</span>
                                <span v-else>{{$t('forget_sendcodeAgain')+'('+count+'s)'}}</span>

                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-button class="forgetBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="next()">{{ $t('forget_nextbtn') }}</el-button>
                    
                </div>
                <div v-show="step == 2">
                    <el-form   class="forgetForm" ref="changeForm" :rules="rules" label-position="top" label-width="80px" :model="changeForm">
                        <el-form-item :label="$t('login_password')" prop="password" >
                            <el-input v-model="changeForm.password" autocomplete="off"  type="password" show-password></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('login_confirmpassword')" prop="confirm"  >
                            <el-input v-model="changeForm.confirm"  autocomplete="off"  type="password" show-password ></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button class="forgetBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="forget()">{{ $t('MyFollowers_Submit') }}</el-button>
                    
                </div>
                
            </div>
            <div class="forgetRight">
                 <img  src="../../assets/images/LoginImg.svg" alt="">
            </div>
        </div>
        <div class="forgetBottom">
            {{ $t('login_bottomTips') }}
        </div>
        <el-dialog class="popupMoudule" width="324px" :visible.sync="isPopupVisible" center :close-on-click-modal="false" :show-close="false" >
            <div slot="title">
                <img src="../../assets/images/NotifySuccess.svg" alt="">
                <p class="popupMouduleTitle">
                    {{ $t('resetSuccess') }}
                </p>
                <p class="popupMouduleContent">
                    {{ backTime + $t('back60Txt') }}
                </p>
            </div>
            
            <span slot="footer" class="btnOne" >
                <el-button type="primary" @click="confirmClick()">{{ $t('backHand') }}</el-button>

            </span>
            
        </el-dialog>
    </div>
  </template>
    
  <script>
  // @ is an alias to /src
  import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'

  
  export default {
    name: "forget",
    components: {
        PopupTips
    },
    computed: {
      ...mapState(["mobileFlag", "lang"]),
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_passwordNotNull')));
            } else {
                var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}');
                if(!pwdRegex.test(value)){
                    callback(new Error(this.$t('login_passwordNum')));
                }else if (this.changeForm.confirm !== '') {
                    this.$refs.changeForm.validateField('confirm');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_password2NotNull')));
            } else if (value !== this.changeForm.password) {
                callback(new Error(this.$t('login_password2Notcorrect')));
            } else {
                callback();
            }
        };
      return {
        languageTxt:'',
        forgetForm:{
            email:'',
            password:''
        },
        fullscreenLoading:false,
        show:true,
        count:60,
        dis:false,
        isGrey: false, //按钮样式
        timer: null, //设置计时器,
        step:1,
        changeForm:{
            password:'',
            confirm:''
        },
        rules: {
          password: [
            { validator: validatePass, trigger: 'blur' }
          ],
          confirm: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        },
        isPopupVisible: false,
        iconImg:require('../../assets/images/NotifySuccess.svg'),
        popupContent:'',
        confirmTxt:'backHand',
        backTime:10
      };
    },
    created() {
        this.languageTxt =  this.lang
    },
    methods: {
        changeLanguage(tag){
            
            if(tag == 'zh'){
                this.$i18n.locale = "en";
	            // localStorage.setItem('localeLanguage', lang)
                this.$store.commit("setLang",'en');
                this.languageTxt = 'en'
            }else{
                this.$i18n.locale = "zh";
                this.$store.commit("setLang",'zh');
                this.languageTxt = 'zh'

            }
        },
        confirmClick(){
            clearInterval(this.timer);
            this.$router.push({ path: '/Login'});
        },
        sendCode() {
            this.$refs.forgetForm.validateField('email', valid => {
                if (!valid) {
                    this.fullscreenLoading = true
                    
                    this.$axios.get('/Api/Login/SendCode?email=' + this.forgetForm.email).then((res) => {
                            let dt = res.data
                            if (dt.isSuccess) {
                                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('send_success')});
                                this.code60(60)
                            } else {
                                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                            }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
                
           
        },
        code60(time){
            let TIME_COUNT = time;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.dis = true;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) { 
                        this.count--;
                    } else {
                        this.dis = false;
                        this.isGrey = false;
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        next(){
            this.$refs.forgetForm.validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    this.$axios
                    .post("/Api/Login/VerifyCode", this.forgetForm)
                    .then((res) => {
                        let dt = res.data;
                        if (dt.isSuccess) {
                            this.step = 2
                        } else {
                            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                        }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        forget(){
            this.$refs.changeForm.validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    let params = {
                        confirm: this.Rsa.encrypt(this.changeForm.confirm.trim()),
                        email: this.forgetForm.email.trim(),
                        password: this.Rsa.encrypt(this.changeForm.password.trim()),
                    }
                    this.$axios
                    .post("/Api/Login/UpdatePassword", params)
                    .then((res) => {
                        let dt = res.data;
                        if (dt.isSuccess) {
                                this.isPopupVisible = true
                                var that = this
                                that.timer = setInterval(() => {
                                    if (that.backTime > 0 && that.backTime <= 10) { 
                                        that.backTime--;
                                    } else {
                                        clearInterval(that.timer);
                                        that.timer = null;
                                        that.$router.push({ path: '/Login'});
                                    }
                                }, 1000);
                        } else {
                            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                        }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
  };
  </script>
  <style scoped lang="less">
 .forget{
    background: #D5EAFD;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;

    .forgetDiv{
        margin: 0  auto 20px auto;
        padding-top: 80px;
        margin: auto auto;
        max-width: 1200px;
        max-height: 700px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .forgetLeft{
            width: calc(50% - 160px);
            background: #fff;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            padding: 80px 80px;
            .forgetTitle{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .forgetTxt{
                    color: #0B205D;
                    font-family: 'PingFangSC-Semibold';
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
                .forgetLanguage{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    .languageIcon{
                        width: 20px;
                        height: 20px;
                    }
                    .languageTxt{
                        line-height: 20px;
                        color: #0B205D;
                        text-align: right;
                        font-family: 'PingFangSC-Medium';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                }
            }
            .forgetForm{
                margin-top: 40px;
                .sendCodeBtn{
                    border-radius: 4px;
                    background: #4D71FF;
                    padding: 8px 13px;
                    color: #FFF;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    position: absolute;
                    right: 16px;
                    top: 8px;
                }
            }
            .forgetTips{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
                span{
                    color: #000;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
                b{
                    color: #4D71FF;
                    text-align: right;
                    font-family: 'PingFangSC-Medium';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .forgetRight{
            
            width: 50%;
            img{
                height: 100%;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }
        .forgetBtn{
            border-radius: 4px;
            background: #0F1845;
            display: flex;
            width: 440px;
            padding: 10px 30px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-family: 'PingFangSC-Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 40px;

        }
    }
    .forgetBottom{
        color: #0F1845;
        font-family: 'PingFangSC-Medium';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 1200px;
        margin: 50px  auto 0 auto;
        padding-bottom: 30px;
        opacity: 0.3;
    }
 }
 @media screen and (max-width:767px) {
    .forget{
        background: #fff;
        .forgetDiv{
            position: relative;
            display: flex;
            flex-direction: column;
            top: 0;
            .forgetLeft{
                width: calc(100% - 40px);
                padding: 30px 20px;
                .forgetTitle{
                    .forgetTxt{
                        font-size: 18px;
                    }
                    .forgetForm{
                        margin-top: 30px;
                    }
                    
                }
                .forgetTips{
                    span,b{
                        font-size: 14px;
                    }
                }
            }
            .forgetBtn{
                width: 100%;
                background-color: #4D71FF;
                font-size: 14px;
            }
            
            .forgetRight{
                display: none;
            }
        }
        .forgetBottom{
            padding: 20px 20px 35px 20px;
            bottom: 0;
            background: #F4F4F4;
            color: #0F1845;
            font-size: 12px;
            position: relative;
        }
    }
 }
  </style>
    